import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location !== null && prevLocation !== null) {
    const routeFired = new CustomEvent('routeNavigated', {
      prevLocation,
      location,
    });
    document.dispatchEvent(routeFired);
  }
};
