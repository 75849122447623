import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
    ${MEDIA.PHONE`
    
    padding-left:5rem;

  `};
  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
  
  .home {
  
  .homeIcon {
    width: 25px;
    display: inline-block;
    margin-right: 10px;
    
        ${MEDIA.PHONE`
    
     width: 62px;
    position: absolute;
    /* margin-right: 10px; */
    left: -24px;
    margin-top: 3px;

  `};

  } 
  
  h1 {
  
    display:inline;
     ${MEDIA.DESKTOP`

    display:inline-block;
  `};

    ${MEDIA.TABLET`
    
    display:inline-block;
  `};

    ${MEDIA.PHONE`
    
    display:inline;

  `};
  }
  }
  
  }
`;
