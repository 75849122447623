import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './footer.css';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <Container>
      <div className="footer--item footer--item__initial">
        <h4>Weber & Urheim</h4>
        <address>
          Strandøre 6b
          <br />
          2100 Copenhagen
          <br />
          Denmark
        </address>
      </div>
      <div className="footer--item">
        <h4>Om Weber & Urheim</h4>
        <span>
          <Link to="/about">Om os</Link>
          <br />
          <a href="mailto:info@weberurheim.com">Kontakt os</a>
          <br />
        </span>
      </div>
    </Container>
  );
};

export default Footer;
