// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-productions-template-productions-js": () => import("/Users/kk/_Dev/kat/wu2/src/pages/productions/_template_productions.js" /* webpackChunkName: "component---src-pages-productions-template-productions-js" */),
  "component---src-pages-productions-episode-player-js": () => import("/Users/kk/_Dev/kat/wu2/src/pages/productions/_episode_player.js" /* webpackChunkName: "component---src-pages-productions-episode-player-js" */),
  "component---src-pages-404-js": () => import("/Users/kk/_Dev/kat/wu2/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/kk/_Dev/kat/wu2/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-copyrights-js": () => import("/Users/kk/_Dev/kat/wu2/src/pages/copyrights.js" /* webpackChunkName: "component---src-pages-copyrights-js" */),
  "component---src-pages-index-js": () => import("/Users/kk/_Dev/kat/wu2/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-productions-index-js": () => import("/Users/kk/_Dev/kat/wu2/src/pages/productions/index.js" /* webpackChunkName: "component---src-pages-productions-index-js" */)
}

