import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';

import Icon from '../../../content/images/icon_as_svg.svg';

const Header = ({ title }) => (
  <Container>
    <Link to="/" className="home">
      <Icon className="homeIcon" />
      <Title as="h1">{title}</Title>
    </Link>

    <Nav />
  </Container>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
