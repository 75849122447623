import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  padding: 0 4rem;
  margin: 2rem 0;

  .footer--item {
    border-top: 5px solid #fff;
    margin: 2rem 0;
    padding: 2rem 0;
    a {
      display: inline-block;
      white-space: nowrap;
      text-decoration: none;
      line-height: 2.5rem;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .footer--item__initial {
    border-top: 5px solid red;
  }

  address {
    line-height: 2.5rem;
    font-size: 1.4rem;
  }
  h4 {
    font-weight: 600;
    padding: 0 0 1.75rem;
  }
`;
